.flip-vertical-right {
    animation: flip-vertical-right 2s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite alternate both;
}

/**
 * ----------------------------------------
 * animation flip-vertical-right
 * ----------------------------------------
 */

@keyframes flip-vertical-right {
    0% {
        transform: rotateY(0);
    }

    100% {
        transform: rotateY(180deg);
    }
}