@font-face {
    font-family: "gaxon";
    src: url(https://madmin.tvspro99.com/static/media/gaxon.9f281cc5.eot);
    src: url(https://madmin.tvspro99.com/static/media/gaxon.9f281cc5.eot?#iefix) format("embedded-opentype"), url(https://madmin.tvspro99.com/static/media/gaxon.59f5815e.woff) format("woff"), url(https://madmin.tvspro99.com/static/media/gaxon.2b3f572a.ttf) format("truetype"), url(https://madmin.tvspro99.com/static/media/gaxon.09f6e1a5.svg#gaxon) format("svg");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "NoirPro";
    src: url(https://madmin.tvspro99.com/static/media/NoirPro-Light.0c5156a6.eot?#iefix) format("embedded-opentype"), url(https://madmin.tvspro99.com/static/media/NoirPro-Light.b9cb1c27.woff) format("woff"), url(https://madmin.tvspro99.com/static/media/NoirPro-Light.d145a875.woff2) format("woff2");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: "NoirPro";
    src: url(https://madmin.tvspro99.com/static/media/NoirPro-Regular.33474975.eot?#iefix) format("embedded-opentype"), url(https://madmin.tvspro99.com/static/media/NoirPro-Regular.437841a0.woff) format("woff"), url(https://madmin.tvspro99.com/static/media/NoirPro-Regular.38a6e5bf.woff2) format("woff2");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "NoirPro";
    src: url(https://madmin.tvspro99.com/static/media/NoirPro-Medium.06d8b758.eot?#iefix) format("embedded-opentype"), url(https://madmin.tvspro99.com/static/media/NoirPro-Medium.a4383e1d.woff) format("woff"), url(https://madmin.tvspro99.com/static/media/NoirPro-Medium.715c4044.woff2) format("woff2");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "NoirPro";
    src: url(https://madmin.tvspro99.com/static/media/NoirPro-SemiBold.b6449710.eot?#iefix) format("embedded-opentype"), url(https://madmin.tvspro99.com/static/media/NoirPro-SemiBold.f631a808.woff) format("woff"), url(https://madmin.tvspro99.com/static/media/NoirPro-SemiBold.94659a79.woff2) format("woff2");
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: "NoirPro";
    src: url(https://madmin.tvspro99.com/static/media/NoirPro-Bold.24429603.eot?#iefix) format("embedded-opentype"), url(https://madmin.tvspro99.com/static/media/NoirPro-Bold.d790118f.woff) format("woff"), url(https://madmin.tvspro99.com/static/media/NoirPro-Bold.fa1d4e28.woff2) format("woff2");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: "NoirPro";
    src: url(https://madmin.tvspro99.com/static/media/NoirPro-Heavy.49715936.eot?#iefix) format("embedded-opentype"), url(https://madmin.tvspro99.com/static/media/NoirPro-Heavy.d8a492a7.woff) format("woff"), url(https://madmin.tvspro99.com/static/media/NoirPro-Heavy.fd447230.woff2) format("woff2");
    font-weight: 900;
    font-style: normal;
}
@media all {
body[data-v-89a24898] {
        color: #545454;
        font-size: 14px;
        font-family: sans-serif;
        font-feature-settings: "tnum";
        font-variant: tabular-nums;
        line-height: 1.15;
        font-weight: 400;
}
}
body[data-v-89a24898] {
    color: #545454;
    font-size: 14px;
    font-family: sans-serif;
    font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.15;
    font-weight: 400;
}
@media all {
*[data-v-89a24898] {
        box-sizing: border-box;
}
}
*[data-v-89a24898] {
    box-sizing: border-box;
}
body[data-v-89a24898] {
    padding: 0;
    margin: 0;
}
@media all {
body[data-v-89a24898] {
        width: 100%;
        height: 100%;
}
body[data-v-89a24898] {
        margin: 0;
        color: #545454;
        font-size: 14px;
        font-family: 'NoirPro', sans-serif;
        font-variant: tabular-nums;
        line-height: 1.3;
        background-color: #f5f5f5;
        font-feature-settings: 'tnum', "tnum";
}
body[data-v-89a24898] {
        overflow: hidden;
        transition: all .3s ease-out;
        width: 100% !important;
        position: relative;
        font-weight: 400;
}
}
body[data-v-89a24898] {
    width: 100%;
    height: 100%;
}
body[data-v-89a24898] {
    margin: 0;
    color: #545454;
    font-size: 14px;
    font-family: "Arial", sans-serif;
    font-variant: tabular-nums;
    line-height: 1.3;
    background-color: #f5f5f5;
    font-feature-settings: "tnum", "tnum", "tnum";
}
body[data-v-89a24898] {
    overflow: hidden;
    transition: all .3s ease-out;
    width: 100% !important;
    position: relative;
    font-weight: 400;
}
@media all {
html[data-v-89a24898] {
        width: 100%;
        height: 100%;
}
html[data-v-89a24898] {
        font-family: sans-serif;
        line-height: 1.15;
        -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
        -ms-overflow-style: scrollbar;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
}
html[data-v-89a24898] {
    width: 100%;
    height: 100%;
}
html[data-v-89a24898] {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@media all {
*[data-v-89a24898],[data-v-89a24898]:before,[data-v-89a24898]:after {
        box-sizing: border-box;
}
}
*[data-v-89a24898],[data-v-89a24898]:after,[data-v-89a24898]:before {
    box-sizing: border-box;
}
@media all {
[data-v-89a24898]:selection {
        color: #fff;
        background: #038fde;
}
}
[data-v-89a24898]:selection {
    color: #fff;
    background: #038fde;
}
@media all {
.ant-modal-mask[data-v-89a24898] {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1000;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.45);
}
}
.ant-modal-mask[data-v-89a24898] {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    height: 100%;
    background-color: rgba(0, 0, 0, .45);
}
@media all {
.ant-modal-wrap[data-v-89a24898] {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: auto;
        outline: 0;
        -webkit-overflow-scrolling: touch;
}
.ant-modal-wrap[data-v-89a24898] {
        z-index: 1000;
}
}
.ant-modal-wrap[data-v-89a24898] {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    outline: 0;
    z-index: 1000;
}
@media all {
[tabindex="-1"][data-v-89a24898]:focus {
        outline: none !important;
}
}
[tabindex="-1"][data-v-89a24898]:focus {
    outline: none !important;
}
@media all {
.ant-modal[data-v-89a24898] {
        box-sizing: border-box;
        margin: 0 auto;
        padding: 0;
        color: #545454;
        font-size: 14px;
        font-variant: tabular-nums;
        line-height: 1.3;
        list-style: none;
        font-feature-settings: 'tnum', "tnum";
        pointer-events: none;
        position: relative;
        top: 100px;
        width: auto;
        max-width: calc(100vw - 32px);
        padding-bottom: 24px;
}
.gx-px-3[data-v-89a24898] {
        padding-right: 1rem !important;
}
.gx-px-3[data-v-89a24898] {
        padding-left: 1rem !important;
}
}
.ant-modal[data-v-89a24898] {
    box-sizing: border-box;
    color: #545454;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.3;
    list-style: none;
    font-feature-settings: "tnum", "tnum", "tnum";
    pointer-events: none;
    position: relative;
    top: 100px;
    width: auto;
    max-width: calc(100vw - 32px);
    margin: 0 auto;
    padding: 0 0 24px;
}
.gx-px-3[data-v-89a24898] {
    padding-right: 1rem !important;
}
.gx-px-3[data-v-89a24898] {
    padding-left: 1rem !important;
}
@media all {
.ant-modal-content[data-v-89a24898] {
        position: relative;
        background-color: #fff;
        background-clip: padding-box;
        border: 0;
        border-radius: 6px;
        box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
        pointer-events: auto;
}
}
.ant-modal-content[data-v-89a24898] {
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-radius: 6px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, .12), 0 6px 16px 0 rgba(0, 0, 0, .08), 0 9px 28px 8px rgba(0, 0, 0, .05);
    pointer-events: auto;
}
.ant-modal-content[data-v-89a24898] {
    border-radius: 0 !important;
}
@media all {
button[data-v-89a24898] {
        touch-action: manipulation;
}
button[data-v-89a24898] {
        margin: 0;
        color: inherit;
        font-size: inherit;
        font-family: inherit;
        line-height: inherit;
}
button[data-v-89a24898] {
        overflow: visible;
}
button[data-v-89a24898] {
        text-transform: none;
}
button[data-v-89a24898] {
        outline: none;
}
}
button[data-v-89a24898] {
    touch-action: manipulation;
}
button[data-v-89a24898] {
    margin: 0;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
}
button[data-v-89a24898] {
    overflow: visible;
}
button[data-v-89a24898] {
    text-transform: none;
}
button[data-v-89a24898] {
    outline: none;
}
@media all {
.ant-modal-close[data-v-89a24898] {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 10;
        padding: 0;
        color: #595959;
        font-weight: 700;
        line-height: 1;
        text-decoration: none;
        background: transparent;
        border: 0;
        outline: 0;
        cursor: pointer;
        transition: color .3s;
}
}
.ant-modal-close[data-v-89a24898] {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    padding: 0;
    color: #595959;
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    transition: color .3s;
}
@media all {
button[data-v-89a24898],
    [type="button"][data-v-89a24898] {
        -webkit-appearance: button;
}
}
button[data-v-89a24898],
[type="button"][data-v-89a24898] {
    -webkit-appearance: button;
}
@media all {
.ant-modal-close[data-v-89a24898]:hover {
        color: rgba(0, 0, 0, 0.75);
        text-decoration: none;
}
}
.ant-modal-close[data-v-89a24898]:hover {
    color: rgba(0, 0, 0, .75);
    text-decoration: none;
}
@media all {
.ant-modal-header[data-v-89a24898] {
        padding: 16px 24px;
        color: #545454;
        background: #fff;
        border-bottom: 1px solid #e8e8e8;
        border-radius: 6px 6px 0 0;
}
}
.ant-modal-header[data-v-89a24898] {
    padding: 16px 24px;
    color: #545454;
    background: #fff;
    border-bottom: 1px solid #e8e8e8;
    border-radius: 6px 6px 0 0;
}
.ant-modal-header[data-v-89a24898] {
    border-radius: 0 !important;
}
.ant-modal-header[data-v-89a24898] {
    background-color: var(--primary-color) !important;
}
@media all {
.ant-modal-body[data-v-89a24898] {
        padding: 24px;
        font-size: 14px;
        line-height: 1.3;
        word-wrap: break-word;
}
}
.ant-modal-body[data-v-89a24898] {
    padding: 24px;
    font-size: 14px;
    line-height: 1.3;
    word-wrap: break-word;
}
@media all {
.ant-modal-footer[data-v-89a24898] {
        padding: 10px 16px;
        text-align: right;
        background: transparent;
        border-top: 1px solid #e8e8e8;
        border-radius: 0 0 6px 6px;
}
}
.ant-modal-footer[data-v-89a24898] {
    padding: 10px 16px;
    text-align: right;
    background: transparent;
    border-top: 1px solid #e8e8e8;
    border-radius: 0 0 6px 6px;
}
.ant-modal-footer[data-v-89a24898] {
    border-radius: 0 !important;
}
@media all {
.ant-modal-close-x[data-v-89a24898] {
        display: block;
        width: 56px;
        height: 56px;
        font-size: 16px;
        font-style: normal;
        line-height: 56px;
        text-align: center;
        text-transform: none;
        text-rendering: auto;
}
}
.ant-modal-close-x[data-v-89a24898] {
    display: block;
    width: 54px;
    height: 54px;
    font-size: 16px;
    font-style: normal;
    line-height: 54px;
    text-align: center;
    text-transform: none;
    text-rendering: auto;
}
.ant-modal-close-x[data-v-89a24898] {
    color: #fff !important;
}
@media all {
.ant-modal-title[data-v-89a24898] {
        margin: 0;
        color: #535353;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        word-wrap: break-word;
}
}
.ant-modal-title[data-v-89a24898] {
    margin: 0;
    color: #535353;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    word-wrap: break-word;
}
.ant-modal-title[data-v-89a24898] {
    color: #fff !important;
}
@media all {
textarea[data-v-89a24898] {
        -webkit-appearance: none;
}
textarea[data-v-89a24898] {
        touch-action: manipulation;
}
textarea[data-v-89a24898] {
        margin: 0;
        color: inherit;
        font-size: inherit;
        font-family: inherit;
        line-height: inherit;
}
textarea[data-v-89a24898] {
        overflow: auto;
        resize: vertical;
}
}
textarea[data-v-89a24898] {
    -webkit-appearance: none;
}
textarea[data-v-89a24898] {
    touch-action: manipulation;
}
textarea[data-v-89a24898] {
    margin: 0;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
}
textarea[data-v-89a24898] {
    overflow: auto;
    resize: vertical;
}
@media all {
.ant-input[data-v-89a24898] {
        box-sizing: border-box;
        margin: 0;
        padding: 7.9px 11px;
        font-variant: tabular-nums;
        list-style: none;
        font-feature-settings: 'tnum', "tnum";
        position: relative;
        display: inline-block;
        width: 100%;
        min-width: 0;
        color: #545454;
        font-size: 14px;
        line-height: 1.3;
        background-color: #fff;
        background-image: none;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        transition: all .3s;
}
}
.ant-input[data-v-89a24898] {
    box-sizing: border-box;
    margin: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum", "tnum", "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 7.9px 11px;
    color: #545454;
    font-size: 14px;
    line-height: 1.3;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    transition: all .3s;
}
@media all {
textarea.ant-input[data-v-89a24898] {
        max-width: 100%;
        height: auto;
        min-height: 36px;
        line-height: 1.3;
        vertical-align: bottom;
        transition: all .3s, height 0s;
}
}
textarea.ant-input[data-v-89a24898] {
    max-width: 100%;
    height: auto;
    min-height: 36px;
    line-height: 1.3;
    vertical-align: bottom;
    transition: all .3s, height 0s;
}
@media all {
.ant-btn[data-v-89a24898] {
        line-height: 34px;
        position: relative;
        background-image: none;
        border: solid 1px #d9d9d9;
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
        cursor: pointer;
        transition: all .3s cubic-bezier(.645, .045, .355, 1);
        -webkit-user-select: none;
                user-select: none;
        touch-action: manipulation;
        padding: 0 15px;
        display: inline-block;
        font-weight: 400;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        height: 36px;
        font-size: 14px;
        border-radius: 4px;
        margin-bottom: 15px;
        color: #545454;
        background: #fff;
        border-color: #d9d9d9;
}
.ant-btn[data-v-89a24898] {
        outline: 0;
}
.ant-btn-primary[data-v-89a24898] {
        color: #ffffff !important;
        background: #038fde;
        border-color: #038fde !important;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
        background-color: #038fde !important;
}
.gx-pointer[data-v-89a24898] {
        cursor: pointer;
}
.ant-btn[data-v-89a24898] {
        line-height: 34px;
        display: inline-block;
        vertical-align: middle;
}
}
.ant-btn[data-v-89a24898] {
    line-height: 1.3;
    position: relative;
    background-image: none;
    box-shadow: 0 2px 0 rgba(0, 0, 0, .015);
    cursor: pointer;
    transition: all .3s cubic-bezier(.645, .045, .355, 1);
    -webkit-user-select: none;
    user-select: none;
    touch-action: manipulation;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    padding: 0 15px;
    height: 36px;
    font-size: 14px;
    border-radius: 4px;
    margin-bottom: 15px;
    color: #545454;
    border: 1px solid #d9d9d9;
    background: #fff;
}
.ant-btn[data-v-89a24898] {
    outline: 0;
}
.ant-btn-primary[data-v-89a24898] {
    color: #fff !important;
    border-color: #038fde !important;
    background: #038fde;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, .045);
    background-color: #038fde !important;
}
.gx-pointer[data-v-89a24898] {
    cursor: pointer;
}
.ant-btn[data-v-89a24898] {
    line-height: 34px;
    display: inline-block;
    vertical-align: middle;
}
@media all {
.ant-btn[data-v-89a24898]:not(:last-child) {
        margin-right: 15px;
}
.ant-modal-footer .ant-btn[data-v-89a24898] {
        margin-bottom: 0;
}
}
.ant-btn[data-v-89a24898]:not(:last-child) {
    margin-right: 15px;
}
.ant-modal-footer .ant-btn[data-v-89a24898] {
    margin-bottom: 0;
}
@media all {
.ant-btn[data-v-89a24898]:not([disabled]):not(.disabled) {
        cursor: pointer;
}
}
.ant-btn[data-v-89a24898]:not([disabled]):not(.disabled) {
    cursor: pointer;
}
@media all {
.ant-btn[data-v-89a24898]:before {
        position: absolute;
        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px;
        z-index: 1;
        display: none;
        background: #fff;
        border-radius: inherit;
        opacity: .35;
        transition: opacity .2s;
        content: '';
        pointer-events: none;
}
}
.ant-btn[data-v-89a24898]:before {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    z-index: 1;
    display: none;
    background: #fff;
    border-radius: inherit;
    opacity: .35;
    transition: opacity .2s;
    content: "";
    pointer-events: none;
}
@media all {
.ant-btn[data-v-89a24898]:hover {
        text-decoration: none;
}
.ant-btn[data-v-89a24898]:hover {
        color: #28aaeb;
        background: #fff;
        border-color: #28aaeb;
}
.ant-btn[data-v-89a24898]:hover,
    .ant-btn[data-v-89a24898]:active {
        text-decoration: none;
        background: #fff;
}
.ant-btn-primary[data-v-89a24898]:hover {
        color: #fff;
        background: #28aaeb;
        border-color: #28aaeb;
}
.ant-btn-primary[data-v-89a24898]:hover {
        color: #ffffff !important;
        background-color: #038fde !important;
        border-color: #038fde !important;
}
}
.ant-btn[data-v-89a24898]:hover {
    text-decoration: none;
}
.ant-btn[data-v-89a24898]:hover {
    color: #28aaeb;
    border-color: #28aaeb;
    background: #fff;
}
.ant-btn[data-v-89a24898]:active,
.ant-btn[data-v-89a24898]:hover {
    text-decoration: none;
    background: #fff;
}
.ant-btn-primary[data-v-89a24898]:hover {
    color: #fff;
    border-color: #28aaeb;
    background: #28aaeb;
}
.ant-btn-primary[data-v-89a24898]:hover {
    color: #fff !important;
}
.ant-btn-primary[data-v-89a24898]:hover {
    background-color: #038fde !important;
    border-color: #038fde !important;
}
@media all {
.ant-btn[data-v-89a24898]:not([disabled]):hover {
        text-decoration: none;
}
}
.ant-btn[data-v-89a24898]:not([disabled]):hover {
    text-decoration: none;
}
@media all {
.gx-bg-grey[data-v-89a24898] {
        background-color: #ccc !important;
}
.gx-text-light-grey[data-v-89a24898] {
        color: #ccc !important;
}
}
.gx-bg-grey[data-v-89a24898] {
    background-color: var(--primary-color) !important;
}
.gx-text-light-grey[data-v-89a24898] {
    color: #ccc !important;
}
@media all {
.ant-modal-footer .ant-btn+.ant-btn[data-v-89a24898]:not(.ant-dropdown-trigger) {
        margin-bottom: 0;
        margin-left: 8px;
}
}
.ant-modal-footer .ant-btn+.ant-btn[data-v-89a24898]:not(.ant-dropdown-trigger) {
    margin-bottom: 0;
    margin-left: 8px;
}
.anticon[data-v-89a24898] {
    display: inline-flex;
    alignItems: center;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
@media all {
.anticon[data-v-89a24898] {
        display: inline-block;
        color: inherit;
        font-style: normal;
        line-height: 0;
        text-align: center;
        text-transform: none;
        vertical-align: -0.125em;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
}
}
.anticon[data-v-89a24898] {
    display: inline-flex;
    align-items: center;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -.125em;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.anticon[data-v-89a24898]:before {
    display: none;
}
@media all {
.anticon[data-v-89a24898]:before {
        display: none;
}
.ant-btn>span[data-v-89a24898] {
        display: inline-block;
}
}
.ant-btn>span[data-v-89a24898] {
    display: inline-block;
}
.anticon>*[data-v-89a24898] {
    line-height: 1;
}
@media all {
.anticon>*[data-v-89a24898] {
        line-height: 1;
}
}
.anticon svg[data-v-89a24898] {
    display: inline-block;
}
@media all {
svg[data-v-89a24898]:not(:root) {
        overflow: hidden;
}
.anticon svg[data-v-89a24898] {
        display: inline-block;
}
}
[data-icon][data-v-89a24898]:before {
    content: attr(data-icon);
}
[class*=" icon-"][data-v-89a24898]::before,
[class^="icon-"][data-v-89a24898]:before,
[data-icon][data-v-89a24898]:before {
    font-family: "gaxon" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-feature-settings: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


/* These were inline style tags. Uses id+class to override almost everything */
#style-1GDWy.style-1GDWy[data-v-89a24898] {
    width: 520px;
    transform-origin: 20px 186px;
}
#style-Zo9RE.style-Zo9RE[data-v-89a24898] {
    width: 0px;
    height: 0px;
    overflow: hidden;
    outline: none;
}
#style-s9lB4.style-s9lB4[data-v-89a24898] {
    width: 0px;
    height: 0px;
    overflow: hidden;
    outline: none;
}