@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap');
@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i&display=swap');
body[data-v-8212892e] {
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase !important;
    color: #333;
    background-image: url("https://opt-v3-files.raksahb.com/static/cashsite/brand/6/image/homeExchangeBg.png");
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    background: black;
    background: var(--primary);
}
*[data-v-8212892e] {
    box-sizing: border-box;
}
*[data-v-8212892e] {
    font-family: "Nunito Sans", sans-serif;
    text-transform: uppercase !important;
}
*[data-v-8212892e] {
    -webkit-user-select: none;
            user-select: none;
    color: white;
    color: var(--primary-text);
}
body[data-v-8212892e] {
    margin: 0;
    font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-family: var(--bs-font-sans-serif);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}
body[data-v-8212892e] {
    color: white;
    color: var(--primary-text);
    background: transparent !important;
}
html[data-v-8212892e] {
    background: black;
    background: var(--primary);
    color: white;
    color: var(--primary-text);
}
[data-v-8212892e]:root {
    --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
@media (prefers-reduced-motion: no-preference) {
[data-v-8212892e]:root {
        scroll-behavior: smooth;
}
}
[data-v-8212892e]:root {
    --primary: black;
    --primary-text: white;
    --secondary: #22c1c3;
}
.iADzKJ[data-v-8212892e] {
    background-image: url("https://opt-v3-files.raksahb.com/static/cashsite/brand/6/image/homeExchangeBg.png");
    background-position: 80% center;
    background-repeat: no-repeat;
    width: 102%;
    height: 100vh;
    padding: 0px;
    min-height: 100%;
    display: flex;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 1;
    position: relative;
    overflow-y: scroll;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
}
#style-UzD4O.style-UzD4O[data-v-8212892e] {
    justify-content: space-between;
}
*[data-v-8212892e],[data-v-8212892e]:after,[data-v-8212892e]:before {
    box-sizing: border-box;
}
.jrMOYv[data-v-8212892e] {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px 0px 0px 10px;
}
.gPOtIJ[data-v-8212892e] {
    opacity: 0.95;
    border-radius: 10px;
    width: 85%;
    height: auto;
    max-height: 65%;
    position: relative;
    top: -38%;
}
.hxBqlt[data-v-8212892e] {
    right: 0px;
    z-index: 999;
    cursor: pointer;
    left: 10px;
    top: 10px;
    position: static;
    padding-top: 0.5rem;
}
.bBwrS[data-v-8212892e] {
    position: absolute;
    left: 30%;
    right: 30%;
    top: 0px;
    width: auto;
    height: auto;
    background: #22c1c3;
    background: var(--secondary);
    z-index: 88888;
}
.fnNlPO[data-v-8212892e] {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    background: linear-gradient(rgb(18, 18, 57) 0%, rgb(21, 20, 57) 62%, rgb(21, 20, 57) 100%);
    border-radius: 0px 0px 10px 10px;
    padding: 40px 0px 0px !important;
}
img[data-v-8212892e] {
    vertical-align: middle;
}
img[data-v-8212892e] {
    -webkit-user-drag: none;
    -webkit-user-select: none;
    user-select: none;
}
h4[data-v-8212892e] {
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
}
h4[data-v-8212892e] {
    font-size: calc(1.275rem + .3vw);
}
@media (min-width: 1200px) {
h4[data-v-8212892e] {
        font-size: 1.5rem;
}
}
h4[data-v-8212892e] {
    font-family: Roboto;
}
.dKuVpg[data-v-8212892e] {
    font-weight: 700;
    font-size: 22px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: rgb(255, 255, 255);
    margin: -12px 0px 5px;
}
label[data-v-8212892e] {
    display: inline-block;
}
.lisheX[data-v-8212892e] {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 5px;
    margin: 7px 0px;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(255, 255, 255);
    box-shadow: rgba(37, 40, 63, 0.5) 0px 0px 50px;
    border-radius: 5px;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    font-weight: 400;
    font-size: 14px;
    color: rgb(62, 57, 153);
    position: relative;
}
h1[data-v-8212892e] {
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
}
h1[data-v-8212892e] {
    font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
h1[data-v-8212892e] {
        font-size: 2.5rem;
}
}
h1[data-v-8212892e] {
    font-family: Roboto;
}
.fnNlPO h1[data-v-8212892e] {
    font-size: 12px;
    color: rgb(255, 96, 96);
    margin: 3px;
    font-weight: 500;
}
.jnVwYT[data-v-8212892e] {
    position: relative;
}
.fvDnWc[data-v-8212892e] {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: 10px 10px 0px;
}
.hfHJim[data-v-8212892e] {
    justify-content: flex-start;
}
.fltqBV[data-v-8212892e] {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}
.jfklLT[data-v-8212892e] {
    background-image: url("https://opt-v3-files.raksahb.com/static/mobile/brand/31/image/loginBGfooter.png");
    border-radius: 0px 0px 10px 10px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
.dyrUWh[data-v-8212892e] {
    position: absolute;
    font-size: 0.8rem;
    z-index: 1;
    text-transform: uppercase;
}
input[data-v-8212892e] {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
.lisheX input[data-v-8212892e] {
    border: none;
    width: 94%;
    z-index: 1;
    background: transparent;
    outline: none;
}
.iPXXpm[data-v-8212892e] {
    display: block;
    width: 5px;
    position: absolute;
    right: 25px;
    top: 14px;
}
button[data-v-8212892e] {
    border-radius: 0;
}
button[data-v-8212892e] {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
button[data-v-8212892e] {
    text-transform: none;
}
[type="submit"][data-v-8212892e],
button[data-v-8212892e] {
    -webkit-appearance: button;
}
.QeAZN[data-v-8212892e] {
    background: #22c1c3;
    background: var(--secondary);
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: rgb(255, 255, 255);
    width: 100%;
    padding: 10px;
    border: none;
}
.egDadO[data-v-8212892e] {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 15px 10px 10px;
    transition: 0.2s ease-in-out;
    position: relative;
}
.dyrUWh span[data-v-8212892e] {
    color: red;
    padding-left: 2px;
}
.lisheX input[type="password"][data-v-8212892e] {
    position: relative !important;
}
svg[data-v-8212892e] {
    vertical-align: middle;
}
.chXqJk[data-v-8212892e] {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0px;
    margin-bottom: 8px;
}
.jfklLT img[data-v-8212892e] {
    width: 100% !important;
}
.egDadO img[data-v-8212892e] {
    width: 100%;
    padding: 2px;
}
.jfklLT .award-icon[data-v-8212892e] {
    width: 100% !important;
}
.dRKoPa[data-v-8212892e] {
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: rgb(255, 255, 255);
    font-weight: 500;
    font-size: 11px;
    margin-left: 5px;
}
.fdyjly[data-v-8212892e] {
    font-size: 14px;
    width: 100%;
    border: 0px;
    padding: 0.75em 1.25em;
    caret-color: rgb(8, 32, 121);
    background-color: rgb(249, 249, 249);
    color: rgb(36, 38, 41);
    border-radius: 2px;
    flex-grow: 1;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 4px inset;
}
.chXqJk .sc-ccbnFN[data-v-8212892e] {
    border: 1px solid rgb(70, 67, 194);
    margin: 0px 5px 0px 0px;
    width: 18px !important;
    height: 18px !important;
}
.chXqJk input[type="checkbox"][data-v-8212892e] {
    accent-color: rgb(85, 85, 246);
}
.dRKoPa .agreeAge[data-v-8212892e] {
    display: inline-block;
    width: calc(100% - 23px);
}
.dRKoPa .agreeAgeColor[data-v-8212892e] {
    color: rgb(246, 167, 45);
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
}


/* These were inline style tags. Uses id+class to override almost everything */
#style-UzD4O.style-a4fNN[data-v-8212892e] {
    background-image: url("https://opt-v3-files.raksahb.com/static/mobile/brand/31/image/auth-bg-newAug.jpg");
    background-size: cover;
    background-position: center center;
}
#style-f4okN.style-f4okN[data-v-8212892e] {
    height: 38px;
}
#style-zZUqo.style-zZUqo[data-v-8212892e] {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -100px;
}
#style-G1Rtf.style-G1Rtf[data-v-8212892e] {
    margin-left: 10px;
}
#userName.style-i2gaq[data-v-8212892e] {
    color: black;
}
#style-xO54M.style-xO54M[data-v-8212892e] {
    margin-left: 10px;
}
#password.style-gnQoy[data-v-8212892e] {
    color: black;
}
#style-qRtlt.style-qRtlt[data-v-8212892e] {
    background-color: #22c1c3;
    background-color: var(--secondary);
    color: rgb(136, 136, 136);
}